import React from 'react';
import { Link, graphql } from "gatsby"

const Author = ({node}) => {
  return(
  <li>
    <Link to={node.slug}>
      <h2>{node.title}</h2>
    </Link>
  </li>
  )
}

const AuthorList = ({data}) => (
  <ul>
    {
      data.allContentfulAuthor.edges.map((edge) => 
        <Author key={edge.node.id} node={edge.node}/>
      )
    }
  </ul> 
)

export default AuthorList;

export const pageQuery = graphql`
  query pageQueryAuthor {
    allContentfulAuthor(sort: {order: ASC, fields: createdAt}) {
      edges {
        node {
          id
          name
          title
          slug
          description {
            description
          }
        }
      }
    }
  }
`